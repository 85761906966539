import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { ENV_VARS, getEnvOrDefault } from '@app/shared/env';
import { AppModule } from './app/app.module';

LicenseManager.setLicenseKey(getEnvOrDefault(ENV_VARS.AG_GRID_KEY, 'invalid'));
ModuleRegistry.register(ServerSideRowModelModule);
ModuleRegistry.register(ClientSideRowModelModule);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
