import { Component, NgModule, OnInit, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AuthGuardService, AuthService } from '@rockwell-automation-inc/service';
import { HomeComponent } from './components/home/home.component';
import { LoggerService } from './services/logger.service';
import { Permission } from './services/perm-helper.service';
import { PermGuard } from './shared/perm.guard';

@Component({ template: '' })
export class DummyComponent implements OnInit {
  logger = inject(LoggerService).withContext(DummyComponent);
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}
  ngOnInit(): void {
    this.authService.isAuthenticated$.subscribe((isAuth) => {
      if (!isAuth) {
        return;
      }
      this.logger.log('authenticated; navigating to home page');
      this.router.navigate(['home']);
    });
  }
}

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  { component: HomeComponent, path: 'callback' },
  { component: DummyComponent, path: 'sign-in' },
  {
    canActivate: [AuthGuardService],
    loadChildren: (): Promise<any> => import('../app/components/home/home.module').then((mo) => mo.HomeModule),
    path: 'home',
  },
  {
    canActivate: [AuthGuardService, PermGuard],
    data: {
      requiredPerms: {
        permissions: [Permission.ListTrialCampaign],
        operator: 'any',
      },
    },
    loadChildren: (): Promise<any> => import('../app/components/trials/trials.module').then((mo) => mo.TrialsModule),
    path: 'trials',
  },
  {
    canActivate: [AuthGuardService, PermGuard],
    data: {
      requiredPerms: {
        permissions: [Permission.ListEntitlement],
      },
    },
    loadChildren: (): Promise<any> =>
      import('../app/components/entitlements/entitlements.module').then((mo) => mo.EntitlementsModule),
    path: 'entitlement',
  },
  {
    canActivate: [AuthGuardService, PermGuard],
    data: {
      requiredPerms: { permissions: [Permission.ListUser], operator: 'any' },
    },
    loadChildren: (): Promise<any> => import('../app/components/users/users.module').then((mo) => mo.UsersModule),
    path: 'users',
  },
  {
    canActivate: [AuthGuardService, PermGuard],
    data: {
      requiredPerms: {
        permissions: [Permission.ListTenant],
        operator: 'any',
      },
    },
    loadChildren: (): Promise<any> => import('../app/components/tenants/tenants.module').then((mo) => mo.TenantsModule),
    path: 'organizations',
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
