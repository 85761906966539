import {
  HttpContextToken,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { LoggerService } from '@app/services/logger.service';
import { SKIP_GLOBAL_SPINNER } from '@rockwell-automation-inc/service';
import { finalize, Observable, tap } from 'rxjs';
import { ControlPageFacade } from '../+state/control-page';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  logger = inject(LoggerService).withContext(SpinnerInterceptor);

  @Inject('skipGlobalSpinner') skipTokens: HttpContextToken<boolean>[] = [SKIP_GLOBAL_SPINNER];
  constructor(private readonly controlPageFacade: ControlPageFacade) {
    this.logger.log('constructed');
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (this.skipTokens.some((it) => req.context.get(it))) {
          return;
        }
        if (event.type === HttpEventType.Sent) {
          this.controlPageFacade.incrementInFlightRequests();
          this.logger.debug('+1', req.method, req.url);
        }
      }),
      finalize(() => {
        if (this.skipTokens.some((it) => req.context.get(it))) {
          return;
        }
        this.controlPageFacade.decrementInFlightRequests();
        this.logger.debug('-1', req.method, req.url);
      }),
    );
  }
}
