import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SnackBarFacade } from '@ra-state';
import { IToastConfig, NotificationType, OverlayPosition, ToastDirective } from '@ra-web-tech-ui-toolkit/components';
import { tap } from 'rxjs';
@Component({
  selector: 'app-snackbar',
  styleUrls: ['./snackbar.component.scss'],
  templateUrl: './snackbar.component.html',
})
export class SnackBarComponent {
  navigateTo: string;
  constructor(
    private snackbarFacade: SnackBarFacade,
    public router: Router,
  ) {}
  @ViewChild(ToastDirective, { static: true }) public toastRef: ToastDirective;
  public toastConfig: IToastConfig = {
    delay: 7000,
    position: OverlayPosition['TopRight'],
  };

  displayMessage$ = this.snackbarFacade.displayMessage$.pipe(
    tap((data) => {
      if (data) {
        const title = data.title || '';
        const type = data.type || 'Success';
        this.toastRef.buttons = data.customBtn ?? [];
        this.toastRef.addToast(String(data?.message), NotificationType[type], title);
      }
    }),
  );

  btnClick($event): void {
    if ($event.button.label === 'Download') {
      window.open($event.button.navigateTo, '_self');
      return;
    }
    this.router.navigate([$event.button.navigateTo]);
  }
}
