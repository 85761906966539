@if ((authService.isAuthenticated$ | async) === false) {
<lib-landing (signInClick)="signInClicked()" [landingImageUrl]="landingImageURL"></lib-landing>
<ng-container *ngIf="loginError$ | async"></ng-container>
}
@if (userLoggedIn$ | async) {

@if (getUserNotificationCounts$ | async) {}
@if (notificationAction$ | async) {}
@if (refreshNotificationsGrid$ | async) {}
@if (getUserNotifications$ | async) {}
@if (signalRUserNotifications$ | async) {}
@if (sessionTimedOut$ | async) { }
@if (countDownTimer$ | async) { }

<div class="app-container">
  @if ((userId$ | async) && (userInfo$ | async)) {
  }
  <lib-common-mat-navbar #commonNavbar [displaySlideToggleTheme]="false" [initialTheme]="logoTypeTheme"
    [logotypeClass]="logoTypeThemeClass" [isAuthenticated]="(authService.isAuthenticated$ | async) || false"
    (raLogoClick)="goToHome()" (goToHomeClick)="goToHome()" (menuItemClick)="menuItemClick($event)"
    [enableUserActionsSideNav]="true" [navItems]="(navItems$ | async) || []" [enableUserProfile]="true"
    [accountMenu]="userInfo" [bannerItems]="bannerItems" (logoutClick)="logoutClicked()"
    [enableNotificationsSideNav]="true" [notifications]="(getNotifications$ | async)!"
    [notificationService$]="notificationService$" [totalNotificationsCount]="getTotalNotificationsCount$ | async"
    [notificationsInProgress$]="notificationsInProgress$"
    [unreadNotificationsCount]="(getUnreadNotificationsCount$ | async)!"
    (notificationAction)="notificationAction($event)"
    (notificationCustomActionClick)="notificationCustomActionClicked($event)">
    <div class="app-content-container">
      <div class="main-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </lib-common-mat-navbar>
</div>
}

@if (isLoading$ | async) {
<app-spinner></app-spinner>
}
<app-snackbar></app-snackbar>